<template>
  <CommonLayout v-if="quest">
    <div class="cabinet">
      <div class="cabinet__inner">
        <div class="cabinet__head">
          <div class="cabinet__row">
            <div class="cabinet__col">
              <h1 class="title">Командная цель</h1>
              <div class="cabinet__subtitle">{{ quest.title }}</div>
              <div class="goal-head">
                <div class="goal-head__row">
                  <div class="goal-head__col">
                    <div class="goal-head__title">
                      Статус цели<Tooltip
                        class="goal-head__tooltip"
                        :msg="`<table>
                                <tr><td>Назначена</td><td>подготовьтесь к испытанию</td></tr>
                                <tr><td>В работе</td><td>время постараться!</td></tr>
                                <tr><td>На модерации</td><td>подождите немного</td></tr>
                                <tr><td>Выполнена</td><td>поздравляем, награды ваши</td></tr>
                                <tr><td>НЕ ВЫПОЛНЕНА</td><td>ничего, получится в другой раз</td></tr>
                              </table>`"
                      />
                    </div>
                    <div class="goal-head__subtitle">
                      {{ quest.state | formatTeamQuest }}
                    </div>
                  </div>
                  <div class="goal-head__col">
                    <div class="goal-head__text">
                      Дата начала: {{ quest.startDate | formatDate2 }}<br />Дата
                      окончания: {{ quest.endDate | formatDate2 }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="cabinet__col">
              <div class="goal-legend">
                <div class="goal-legend__inner">
                  <div class="goal-legend__head">
                    <Tooltip
                      v-if="quest.hint"
                      class="goal-legend__tooltip"
                      :msg="quest.hint"
                    />
                    <div class="goal-legend__title">Цели</div>
                  </div>
                  <div class="goal-legend__body">
                    <table>
                      <thead>
                        <tr>
                          <th>Результат:</th>
                          <th>Награды</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(item, index) in quest.targets" :key="index">
                          <td
                            :class="{
                              green: item.targetScore === 5,
                              yellow: item.targetScore === 4,
                              pink: item.targetScore === 3,
                              red: item.targetScore === 2,
                            }"
                          >
                            <div class="overflow" :title="item.value">
                              {{ item.value }}
                            </div>
                          </td>
                          <td>
                            <img
                              src="@/assets/img/pic2/coin.svg"
                              width="15"
                              height="15"
                              alt=""
                            />{{ item.coins | priceFilter }}
                          </td>
                        </tr>
                        <!-- <tr>
                          <td class="green">0,3</td>
                          <td>
                            <img
                              src="@/assets/img/pic2/coin.svg"
                              width="15"
                              height="15"
                              alt=""
                            />2 500
                          </td>
                        </tr>
                        <tr>
                          <td class="yellow">0,5</td>
                          <td>
                            <img
                              src="@/assets/img/pic2/coin.svg"
                              width="15"
                              height="15"
                              alt=""
                            />1 500
                          </td>
                        </tr>
                        <tr>
                          <td class="pink">0,70</td>
                          <td>
                            <img
                              src="@/assets/img/pic2/coin.svg"
                              width="15"
                              height="15"
                              alt=""
                            />500
                          </td>
                        </tr>
                        <tr>
                          <td class="red">0,70</td>
                          <td>
                            <img
                              src="@/assets/img/pic2/coin.svg"
                              width="15"
                              height="15"
                              alt=""
                            />0
                          </td>
                        </tr> -->
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="cabinet__body">
          <div v-if="loading" class="goal-sceleton">
            <Loading position="center" />
          </div>
          <div v-else class="goal">
            <div class="goal__head">
              <div class="goal__col">
                <table class="goal-table">
                  <thead>
                    <tr>
                      <th>Участник команды</th>
                      <th>Результат</th>
                      <th>Получено</th>
                    </tr>
                  </thead>
                </table>
              </div>
              <div class="goal__col">
                <table class="goal-table">
                  <thead>
                    <tr>
                      <th>Участник команды</th>
                      <th>Результат</th>
                      <th>Получено</th>
                    </tr>
                  </thead>
                </table>
              </div>
            </div>
            <div class="goal__body">
              <div class="goal__col">
                <table class="goal-table">
                  <tbody>
                    <tr v-for="(item, index) in playersEven" :key="index">
                      <td>
                        <div>
                          <span :title="item.playerName">{{
                            item.playerName
                          }}</span>
                        </div>
                      </td>
                      <td>
                        <div
                          :class="{
                            green: item.grade === 5,
                            yellow: item.grade === 4,
                            pink: item.grade === 3,
                            red: item.grade === 2,
                          }"
                        >
                          {{ item.score !== null ? item.score : "-" }}
                        </div>
                      </td>
                      <td>
                        <div>
                          <img
                            src="@/assets/img/pic2/coin.svg"
                            width="15"
                            height="15"
                            alt=""
                          />{{ item.coins !== null ? item.coins : "-" }}
                        </div>
                      </td>
                    </tr>
                    <!-- <tr>
                      <td>
                        <div><span>Шестакова Елена Алексеевна</span></div>
                      </td>
                      <td><div>-</div></td>
                      <td>
                        <div>
                          <img
                            src="@/assets/img/pic2/coin.svg"
                            width="15"
                            height="15"
                            alt=""
                          />0
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div><span>Шестакова Елена Алексеевна</span></div>
                      </td>
                      <td><div>-</div></td>
                      <td>
                        <div>
                          <img
                            src="@/assets/img/pic2/coin.svg"
                            width="15"
                            height="15"
                            alt=""
                          />0
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div><span>Шестакова Елена Алексеевна</span></div>
                      </td>
                      <td><div>-</div></td>
                      <td>
                        <div>
                          <img
                            src="@/assets/img/pic2/coin.svg"
                            width="15"
                            height="15"
                            alt=""
                          />0
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div><span>Шестакова Елена Алексеевна</span></div>
                      </td>
                      <td><div>-</div></td>
                      <td>
                        <div>
                          <img
                            src="@/assets/img/pic2/coin.svg"
                            width="15"
                            height="15"
                            alt=""
                          />0
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div><span>Шестакова Елена Алексеевна</span></div>
                      </td>
                      <td><div>-</div></td>
                      <td>
                        <div>
                          <img
                            src="@/assets/img/pic2/coin.svg"
                            width="15"
                            height="15"
                            alt=""
                          />0
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div><span>Шестакова Елена Алексеевна</span></div>
                      </td>
                      <td><div>-</div></td>
                      <td>
                        <div>
                          <img
                            src="@/assets/img/pic2/coin.svg"
                            width="15"
                            height="15"
                            alt=""
                          />0
                        </div>
                      </td>
                    </tr> -->
                  </tbody>
                </table>
              </div>
              <div class="goal__col">
                <table class="goal-table">
                  <tbody>
                    <tr v-for="(item, index) in playersOdd" :key="index">
                      <td>
                        <div>
                          <span :title="item.playerName">{{
                            item.playerName
                          }}</span>
                        </div>
                      </td>
                      <td>
                        <div
                          :class="{
                            green: item.grade === 5,
                            yellow: item.grade === 4,
                            pink: item.grade === 3,
                            red: item.grade === 2,
                          }"
                        >
                          {{ item.score !== null ? item.score : "-" }}
                        </div>
                      </td>
                      <td>
                        <div>
                          <img
                            src="@/assets/img/pic2/coin.svg"
                            width="15"
                            height="15"
                            alt=""
                          />{{ item.coins !== null ? item.coins : "-" }}
                        </div>
                      </td>
                    </tr>
                    <!-- <tr>
                      <td>
                        <div>
                          <span
                            title="Доронина Элеонора Феликсовна (Капитан команды)"
                            >Доронина Элеонора Феликсовна (Капитан
                            команды)</span
                          >
                        </div>
                      </td>
                      <td><div>-</div></td>
                      <td>
                        <div>
                          <img
                            src="@/assets/img/pic2/coin.svg"
                            width="15"
                            height="15"
                            alt=""
                          />0
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div><span>Шестакова Елена Алексеевна</span></div>
                      </td>
                      <td><div>-</div></td>
                      <td>
                        <div>
                          <img
                            src="@/assets/img/pic2/coin.svg"
                            width="15"
                            height="15"
                            alt=""
                          />0
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div><span>Шестакова Елена Алексеевна</span></div>
                      </td>
                      <td><div>-</div></td>
                      <td>
                        <div>
                          <img
                            src="@/assets/img/pic2/coin.svg"
                            width="15"
                            height="15"
                            alt=""
                          />0
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div><span>Шестакова Елена Алексеевна</span></div>
                      </td>
                      <td><div>-</div></td>
                      <td>
                        <div>
                          <img
                            src="@/assets/img/pic2/coin.svg"
                            width="15"
                            height="15"
                            alt=""
                          />0
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div><span>Шестакова Елена Алексеевна</span></div>
                      </td>
                      <td><div>-</div></td>
                      <td>
                        <div>
                          <img
                            src="@/assets/img/pic2/coin.svg"
                            width="15"
                            height="15"
                            alt=""
                          />0
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div><span>Шестакова Елена Алексеевна</span></div>
                      </td>
                      <td><div>-</div></td>
                      <td>
                        <div>
                          <img
                            src="@/assets/img/pic2/coin.svg"
                            width="15"
                            height="15"
                            alt=""
                          />0
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div><span>Шестакова Елена Алексеевна</span></div>
                      </td>
                      <td><div>-</div></td>
                      <td>
                        <div>
                          <img
                            src="@/assets/img/pic2/coin.svg"
                            width="15"
                            height="15"
                            alt=""
                          />0
                        </div>
                      </td>
                    </tr> -->
                  </tbody>
                </table>
              </div>
            </div>
            <div class="goal-foot">
              <div class="goal-foot__title">Результат команды:</div>
              <div class="goal-foot__list">
                <div class="goal-foot__item">
                  <span>Результат</span>
                  <strong
                    :class="{
                      green: quest.finalGrade === 5,
                      yellow: quest.finalGrade === 4,
                      pink: quest.finalGrade === 3,
                      red: quest.finalGrade === 2,
                    }"
                    >{{
                      quest.finalScore !== null ? quest.finalScore : "-"
                    }}</strong
                  >
                </div>
                <div class="goal-foot__item">
                  <span>Награда</span>
                  <strong
                    ><img
                      src="@/assets/img/pic2/coin.svg"
                      width="25"
                      height="25"
                      alt=""
                    />{{
                      quest.finalCoins !== null ? quest.finalCoins : "-"
                    }}</strong
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </CommonLayout>
  <CommonLayout v-else class="page_fullscreen">
    <div class="content">
      <p>
        <strong
          >В разделе «Командная цель» ты сможешь видеть вклад<br />
          каждого члена твоей команды в выполнение общей цели.<br />
          Здесь вы сможете вместе идти к победе, зарабатывая <br />
          GPB-coins и другие награды.
        </strong>
      </p>
      <p>
        Ты узнаешь цель сразу, как только ее назначит <br />
        совет Старейшин. Следи за уведомлениями!
      </p>
      <router-link
        class="button button-default button_big"
        :to="{ name: 'profile' }"
        >В Личный кабинет</router-link
      >
    </div>
  </CommonLayout>
</template>

<script>
import { GetTeamQuest } from "@/api/TeamQuest";
import CommonLayout from "@/layout/CommonLayout.vue";
import Tooltip from "@/components/Tooltip.vue";
import Loading from "@/components/Loading.vue";

export default {
  components: {
    CommonLayout,
    Tooltip,
    Loading,
  },
  computed: {
    playersOdd() {
      return this.players.filter((e, i) => i % 2);
    },
    playersEven() {
      return this.players.filter((e, i) => !(i % 2));
    },
  },
  data() {
    return {
      loading: false,
      quest: null,
      players: [],
    };
  },
  created() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      GetTeamQuest().then((resp) => {
        const { quest, playerStates } = resp.data;

        if (quest) {
          this.quest = quest;
        }

        if (playerStates) {
          this.players = playerStates;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.goal-sceleton {
  width: 100%;
}
.goal-legend {
  background-image: url(~@/assets/img/pics/legend-bg.svg);
  background-repeat: no-repeat;
  background-size: contain;
  width: 322px;
  height: 226px;
  margin-top: 10px;
  &__inner {
    padding: 23px 55px;
  }
  &__head {
    position: relative;
  }
  &__tooltip {
    position: absolute;
    top: 8px;
    right: 100%;
  }
  &__title {
    font-size: 24px;
    color: #50ffeb;
  }
  &__body {
    table {
      width: 100%;
      text-align: left;
      border-spacing: 0px;
      thead {
        tr {
          th {
            font-weight: normal;
            font-size: 18px;
            color: #ade5d9;
          }
        }
      }
      tbody {
        font-weight: 700;
        font-size: 19px;
        tr {
          td {
            white-space: nowrap;
            .overflow {
              width: 150px;
              white-space: nowrap;
              text-overflow: ellipsis;
              overflow: hidden;
              margin-right: 5px;
            }
            img {
              display: inline-block;
              vertical-align: middle;
              margin-top: -2px;
              margin-right: 8px;
            }
          }
        }
      }
    }
  }
}

.goal {
  width: 100%;
  &__head {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
  }
  &__body {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
    overflow: auto;
    @include scrollbar;
    height: calc(100vh - 550px);
    min-height: 280px;
  }
  &__col {
  }
  &__foot {
  }
  &__scroll {
  }
}

.goal-table {
  text-align: left;
  width: 100%;
  border-spacing: 0;
  // border-spacing: 0 5px;
  tr {
    td,
    th {
      &:nth-child(1) {
        width: calc(100% - 240px);
      }
      &:nth-child(2) {
        width: 120px;
        text-align: center;
        white-space: nowrap;
        > div {
          justify-content: center;
        }
      }
      &:nth-child(3) {
        width: 120px;
        text-align: center;
        white-space: nowrap;
        > div {
          justify-content: center;
        }
      }
    }
  }
  thead {
    position: sticky;
    top: 0px;
    tr {
      th {
        &:first-child {
          padding-left: 35px;
        }
        font-weight: normal;
        padding: 0 20px;
        font-size: 16px;
        // line-height: 14px;
        color: #ade5d9;
      }
    }
  }
  tbody {
    tr {
      td {
        font-size: 18px;
        line-height: 22px;
        padding: 0;
        &:first-child {
          > div {
            padding-left: 35px;
          }
        }
        > div {
          background: rgba(0, 255, 255, 0.35);
          min-height: 50px;
          max-height: 65px;
          height: calc((100vh - 595px) / 7);
          padding: 10px 20px;
          margin: 3px 0;
          display: flex;
          align-items: center;
        }
        span {
          overflow: hidden;
          white-space: nowrap;
          max-width: 320px;
          display: block;
          text-overflow: ellipsis;
        }
        img {
          display: inline-block;
          vertical-align: middle;
          margin-right: 5px;
          margin-bottom: -2px;
        }
      }
    }
  }
}

.goal-head {
  margin-top: 15px;
  &__row {
    display: flex;
    align-items: flex-end;
  }
  &__col {
    margin-right: 50px;
  }
  &__tooltip {
    margin-bottom: -8px;
  }
  &__title {
    font-weight: 700;
    font-size: 32px;
    line-height: 37px;
    color: #ade5d9;
    display: flex;
    align-items: center;
  }
  &__subtitle {
    font-weight: 700;
    font-size: 32px;
    line-height: 37px;
    color: #50ffeb;
    text-transform: uppercase;
  }
  &__text {
    font-size: 18px;
    color: #ade5d9;
    margin-bottom: 2px;
  }
}

.goal-foot {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  &__title {
    font-size: 40px;
  }
  &__list {
    display: flex;
    align-items: center;
  }
  &__item {
    margin-left: 40px;
    background: rgba(0, 255, 254, 0.15);
    height: 64px;
    width: 230px;
    text-align: center;
    position: relative;
    background-image: url("data:image/svg+xml,%3Csvg width='22' height='64' viewBox='0 0 22 64' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M22 2H2v60h20' stroke='%2300DFDF' stroke-width='4'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-size: contain;
    &::before {
      background-image: url("data:image/svg+xml,%3Csvg width='22' height='64' viewBox='0 0 22 64' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M22 2H2v60h20' stroke='%2300DFDF' stroke-width='4'/%3E%3C/svg%3E");
      background-repeat: no-repeat;
      background-size: contain;
      position: absolute;
      top: 0;
      right: 0;
      pointer-events: none;
      width: 100%;
      height: 100%;
      transform: rotate(180deg);
      content: "";
    }
    span {
      display: block;
      font-size: 18px;
      color: #50ffeb;
    }
    strong {
      display: flex;
      align-items: center;
      font-weight: 700;
      font-size: 32px;
      line-height: 1;
      justify-content: center;
      img {
        margin-right: 8px;
        margin-bottom: -4px;
      }
    }
  }
}

.content {
  padding-top: 50px;
  padding-bottom: 50px;
  text-align: center;
  p {
    font-size: 20px;
    line-height: 23px;
  }
  .button {
    margin-top: 15px;
  }
}
</style>
